<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="page-title text-center">Anmeldung <span v-if="registered">erfolgreich</span></h1>
        <h2 v-if="!registered" class="display-p text-center mb-4">
          Lernen Sie unsere Produkte und Dienstleistungen kennen!
        </h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-12 col-lg-6">
        <p v-if="registered" class="mb-4 text-center">
          Vielen Dank für Ihre Anmeldung zum Event von scanner4you. Wir freuen
          uns sehr, Sie bei unserem Event begrüssen zu dürfen. Sie erhalten eine
          Bestätigung mit allen Informationen per E-Mail.
        </p>
        <form v-else @submit.prevent="submitForm">
          <div class="row">
            <div class="col field">
              <label class="form-label">Event *</label>
              <select
                class="form-select"
                v-model="location"
                aria-label="Location"
              >
                <option selected disabled>Auswählen</option>
                <option disabled value="rapperswil">Rapperswil</option>
                <option value="zug">Zug</option>
                <option value="zurich">Zürich</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col field">
              <label class="form-label">Vorname *</label>
              <div class="control">
                <input
                  type="text"
                  class="form-input"
                  v-model="first_name"
                  autocomplete="given-name"
                />
              </div>
            </div>
            <div class="col field">
              <label class="form-label">Name *</label>
              <div class="control">
                <input
                  type="text"
                  class="form-input"
                  v-model="last_name"
                  autocomplete="family-name"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="form-label">Firma *</label>
              <div class="field">
                <input
                  type="text"
                  class="form-input"
                  v-model="company"
                  autocomplete="organization"
                />
              </div>
            </div>
            <div class="col">
              <label class="form-label">Branche *</label>
              <select
                class="form-select"
                v-model="sector"
                aria-label="Branche"
              >
                <option selected disabled>Auswählen</option>
                <option value="Architekturbau">Architekturbau</option>
                <option value="Ingenieursbau">Ingenieursbau</option>
                <option value="Schlosser- und Metallbau">
                  Schlosser und Metallbau
                </option>
                <option value="Elektro- und Anlagebau">
                  Elektro- und Anlagebau
                </option>
                <option value="Sanitärgewerbe">Sanitärgewerbe</option>
                <option value="Maurergewerbe">Maurergewerbe</option>
                <option value="Malergewerbe">Malergewerbe</option>
                <option value="Raumplanung">Raumplanung</option>
                <option value="Andere">Andere</option>
              </select>
            </div>
          </div>
          <div class="field">
            <label class="form-label">Strasse *</label>
            <div class="control">
              <input
                type="text"
                class="form-input"
                v-model="address"
                autocomplete="street-address"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 field">
              <label class="form-label">PLZ *</label>
              <div class="control">
                <input
                  type="text"
                  class="form-input"
                  v-model="postalcode"
                  autocomplete="postal-code"
                />
              </div>
            </div>
            <div class="col">
              <label class="form-label">Ort*</label>
              <div class="control">
                <input
                  type="text"
                  class="form-input"
                  v-model="city"
                  autocomplete="address-level2"
                />
              </div>
            </div>
          </div>
          <d class="row">
            <div class="col">
              <label class="form-label">E-Mail *</label>
              <div class="control">
                <input
                  type="text"
                  class="form-input"
                  v-model="email"
                  autocomplete="email"
                />
              </div>
            </div>
            <div class="col field">
              <label class="form-label">Telefon*</label>
              <div class="control">
                <input
                  type="text"
                  class="form-input"
                  v-model="phone"
                  autocomplete="tel"
                />
              </div>
            </div>
          </d>
          <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>
          <div>
            <p>*Pflichtfelder</p>
          </div>
          <button class="login">Kostenlos anmelden</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      location: "Auswählen",
      company: "",
      sector: "Auswählen",
      first_name: "",
      last_name: "",
      address: "",
      postalcode: "",
      city: "",
      phone: "",
      email: "",
      errors: [],
      launchinformationen: "",
      registered: false,
    };
  },
  mounted() {
    document.title = "Ihr Ansprechpartner für Laserscanning - scanner4you";
    this.getLaunchInformationen();
  },
  methods: {
    async getLaunchInformationen() {
      await axios
        .get(`/api/v1/content/eventinformation`)
        .then((response) => {
          this.eventinformation = response.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitForm() {
      this.errors = [];
      if (this.location === "") {
        this.errors.push("Bitte Event auswählen");
      }
      if (this.company === "") {
        this.errors.push("Bitte Firma eingeben");
      }
      if (this.sector === "") {
        this.errors.push("Bitte Branche auswählen");
      }
      if (this.first_name === "") {
        this.errors.push("Bitte Vornamen eingeben");
      }
      if (this.last_name === "") {
        this.errors.push("Bitte Namen eingeben");
      }
      if (this.address === "") {
        this.errors.push("Bitte Adresse eingeben");
      }
      if (this.postalcode === "") {
        this.errors.push("Bitte Postleitzahl eingeben");
      }
      if (this.city === "") {
        this.errors.push("Bitte Ort eingeben");
      }
      if (this.email === "") {
        this.errors.push("Bitte E-Mail Adresse eingeben");
      }
      if (this.phone === "") {
        this.errors.push("Bitte Telefonnummer eingeben");
      }
      if (!this.errors.length) {
        const formData = {
          location: this.location,
          company: this.company,
          sector: this.sector,
          first_name: this.first_name,
          last_name: this.last_name,
          address: this.address,
          postalcode: this.postalcode,
          city: this.city,
          phone: this.phone,
          email: this.email,
        };
        axios
          .post("/api/v1/registration/", formData)
          .then((response) => {
            this.registered = true;
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              this.errors.push(
                "Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut oder melden Sie sich bei uns (071 311 44 00)."
              );
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>
